import { useLazyQuery } from "@apollo/client";
import { useContext } from "react";
import coingeckoChainList from "../config/coingeckoChainList";
import { GET_COINGECKO_PRICE_BY_TOKENADDRESS, GET_COINGECKO_PRICE_BY_TOKENADDRESS_MULTIPLE } from "../config/queries";
import { ChainConfigContext } from "../contexts/ChainConfigContext";
import { STABLE_COINS } from "../config/constants";
import { isStableCoin } from "../utils/tokenUtils";

import coingeckoTokenList from "../config/coingeckoTokenList.json";

export default function useCoingecko() {

  const { getTokenByAddress } = useContext(ChainConfigContext);
  const [coingeckoPriceByTokenAddress] = useLazyQuery(
    GET_COINGECKO_PRICE_BY_TOKENADDRESS,
    { fetchPolicy: "network-only" },
  );
  const [coingeckoPriceByTokenAddressMultiple] = useLazyQuery(
    GET_COINGECKO_PRICE_BY_TOKENADDRESS_MULTIPLE,
    { fetchPolicy: "network-only" }
  );

  const getPriceByTokenAddress = async (
    chainId: number,
    tokenAddress: string,
    currency: string = "usd",
  ) => {
    const correctedParams = getCorrectedCoingeckoParams(chainId, tokenAddress);
    if (!correctedParams) return null;

    const query = await coingeckoPriceByTokenAddress({
      variables: {
        currency,
        tokenAddress: correctedParams.correctedTokenAddress,
        coingeckoChainId: correctedParams.coingeckoChainId,
      },
    });

    let price = query?.data?.coingeckoPriceByTokenAddress;

    if (price === null || price === undefined) {
      const token = getTokenByAddress(tokenAddress, chainId);
      if (token && isStableCoin(token.symbol)) {
        price = 1;
      }
    }

    return price;
  };

  const getPricesByTokenAddresses = async (
    chainId: number,
    tokenAddresses: string[],
    currency: string = "usd"
  ) => {
    const tokenGroups: { [key: string]: string[] } = {};
    const addressMap: { [key: string]: string } = {};
    
    for (const tokenAddress of tokenAddresses) {
      const correctedParams = getCorrectedCoingeckoParams(chainId, tokenAddress);
      if (!correctedParams || !correctedParams.coingeckoChainId) continue;

      const key = correctedParams.coingeckoChainId;
      if (!tokenGroups[key]) {
        tokenGroups[key] = [];
      }
      tokenGroups[key].push(correctedParams.correctedTokenAddress);
      addressMap[correctedParams.correctedTokenAddress] = tokenAddress;
    }

    // Make queries for each chain
    const allPrices: any = [];
    for (const [coingeckoChainId, addresses] of Object.entries(tokenGroups)) {
      const query = await coingeckoPriceByTokenAddressMultiple({
        variables: {
          currency,
          tokenAddresses: addresses,
          coingeckoChainId,
        },
      });

      const prices = query?.data?.coingeckoPriceByTokenAddressMultiple?.prices || [];
      allPrices.push(...prices.map(p => ({
        address: addressMap[p.address],
        price: p.price
      })));
    }

    return allPrices;
  };

  const getCorrectedCoingeckoParams = (
    chainId: number,
    tokenAddress: string,
  ): {
    chainId: number;
    coingeckoChainId: string | null;
    correctedTokenAddress: string;
  } | null => {
    const token = getTokenByAddress(tokenAddress, chainId);
    if (!token) return null;

    let coingeckoChainId = coingeckoChainList.find(
      (e) => e.chain_identifier === chainId,
    )?.id;
    if (!coingeckoChainId) return null;

    let coingeckoToken = coingeckoTokenList.find(
      (e) => 
        e.symbol.toLowerCase() === token?.symbol.toLowerCase() &&
        e.platforms[coingeckoChainId] === tokenAddress,
    );

    let correctedChainId = chainId;
    let correctedTokenAddress = tokenAddress;
    
    if (!coingeckoToken) {
      const matchingTokens = coingeckoTokenList.filter(
        (e) => e.symbol.toLowerCase() === token?.symbol.toLowerCase()
      );
      coingeckoToken = matchingTokens.find(
        (token) => token.platforms['ethereum']
      ) || matchingTokens[0];

      if (coingeckoToken) {
        // Prefer ethereum platform, otherwise take the first available platform
        coingeckoChainId = coingeckoToken.platforms['ethereum'] 
          ? 'ethereum' 
          : Object.keys(coingeckoToken.platforms)[0];

        correctedChainId = parseInt(
          coingeckoChainList.find(
            (e) => e.id === coingeckoChainId,
          )?.chain_identifier?.toString() || `${chainId}`,
        );
        correctedTokenAddress = coingeckoToken?.platforms[coingeckoChainId];
      }
    }

    return {
      chainId: correctedChainId,
      coingeckoChainId: coingeckoChainId,
      correctedTokenAddress: correctedTokenAddress,
    };
  };

  return { 
    getPriceByTokenAddress, 
    getCorrectedCoingeckoParams, 
    getPricesByTokenAddresses 
  };
}
